<template>
  <div class="home">
    <v-container fluid>
      <v-row>
        <v-col xs="12" sm="12" md="3">
          <v-card width="200" height="180">
            <template v-slot:title> Templates Status </template>

            <div v-if="templatsStatus" style="padding: 0 20px">
              <div
                
                style="display: flex; justify-content: space-between; gap: 10px"
                v-for="(val, idx) in templatsStatus"
                :key="idx"
              >
                <span> {{ idx }} </span>
                <v-badge color="#1bb5f3" :content="templatsStatus[idx]" inline></v-badge>
              </div>
              
            </div>
            <div class="text-center" v-else> 
              <v-badge color="#1bb5f3" content="No Data" inline></v-badge>
            </div>

            <!-- <template v-slot:text>
        This is content
      </template> -->
          </v-card>
        </v-col>
        <v-col  xs="12" sm="12" md="3">
          <v-card width="200" height="180" style="overflow:auto">
            <template v-slot:title> Whatsapp Users </template>

            <div v-if="whatsappUsers" style="padding: 0 20px">
              <div
                style="display: flex; justify-content: space-between; gap: 10px"
                v-for="(val, idx) in whatsappUsers"
                :key="idx"
              >
                <span> {{ idx }} </span>
                <v-badge color="#1bb5f3" :content="whatsappUsers[idx]" inline></v-badge>
              </div>
            </div>
            <div class="text-center" v-else> 
              <v-badge color="#1bb5f3" content="No Data" inline></v-badge>
            </div>

            <!-- <template v-slot:text>
        This is content
      </template> -->
          </v-card>
        </v-col>
        <v-col  xs="12" sm="12" md="3">
          <v-card width="200" height="180">
            <template v-slot:title> Instagram Users </template>

            <div v-if="instagramUsers" style="padding: 0 20px">
              <div
                style="display: flex; justify-content: space-between; gap: 10px"
                v-for="(val, idx) in instagramUsers"
                :key="idx"
              >
                <span> {{ idx }} </span>
                <v-badge color="#1bb5f3" :content="instagramUsers[idx]" inline></v-badge>
              </div>
            </div>
            <div class="text-center" v-else> 
              <v-badge color="#1bb5f3" content="No Data" inline></v-badge>
            </div>

            <!-- <template v-slot:text>
        This is content
      </template> -->
          </v-card>
        </v-col>
        <v-col cols="12">
        <v-expansion-panels>
          <v-expansion-panel v-for="(val, idx) in templatsData" :key="idx">
            <v-expansion-panel-title>{{ idx }}</v-expansion-panel-title>
            <div style="min-height: 10px;max-height: 200px;overflow-y: auto;">
              <v-expansion-panel-text v-for="(value, index) in val" :key="index"
                >{{ value.name }}
              </v-expansion-panel-text>
            </div>
            <!-- <v-expansion-panel-text >test</v-expansion-panel-text> -->
          </v-expansion-panel>
        </v-expansion-panels>
        </v-col>
        <v-col cols="9">
          <router-view />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useUserStore } from '../stores/user'
import { useDashboardStore } from '../stores/dashboard'

// import repository from '../api/repository'

export default {
  name: 'HomeView', 
  data: () => ({
    data: null
  }),
  computed: {
    ...mapState(useDashboardStore, ['templatsStatus', 'templatsData', 'whatsappUsers', 'instagramUsers']),
  },
  async mounted() {
    document.title = 'Home';
    // const baseUrl = process.env.VUE_APP_BACK_END_BASE_URL;
    // 
    // eslint-disable-next-line no-undef
    // const socket = io.connect(`${baseUrl}`);
    // console.log('HomeView | mounted', {data: this.data})

    // const res = await repository.index('dashboard')
    // this.data = res?.data
    // this.templatsStatus = res?.data?.templates?.count
    // this.templatsData = res?.data?.templates?.templatesData
    // this.whatsappUsers = res?.data?.users?.whatsapp
    // this.instagramUsers = res?.data?.users?.instagram

    this.getDashboardData();

    // socket.on("chat", async (data) => {
    //  console.log({data})
    // });

    // console.log({ 1: res, res: res?.data?.templates?.templatesData })
  },
  async updated() {
    console.log('HomeView | updated')

    // document.title = 'Home';
    this.getAdmin()
  },
  methods: {
    ...mapActions(useUserStore, ['getUsers', 'getAdmin']),
    ...mapActions(useDashboardStore, ['getDashboardData']),

    //getDashboardData
  },
}
</script>
