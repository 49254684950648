<template>
  <v-container>
    <div class="wellcome-message-container"></div>
    <h1>Wellcome Message</h1>
    <v-textarea
      class="text-area-container"
      clearable
      counter
      no-resize
      rows="10"
      v-model="wellcomeMessage.data"
      label=""
      ref="bar"
    ></v-textarea>
    <div class="emoji-container">
      <v-icon
        class="emoji-icon"
        @click="clickHandler"
        title="emoji"
        icon="mdi-emoticon-happy-outline"
      ></v-icon>
      <EmojiPicker v-if="emojiShow" class="emoji" :native="true" @select="onSelectEmoji" />
    </div>

    <v-btn @click="saveHandler">save</v-btn>
  </v-container>
</template>

<style scoped>
.wellcome-message-container {
  position: relative;
}
.text-area-container {
  position: relative;
}
.v3-emoji-picker {
  position: absolute !important;
  right: 0 !important;
}
.emoji-container {
  position: absolute;
  top: 45%;
  right: 3%;
}

/* .emoji-container {
  position: relative;
} */

/* .emoji-container .emoji {
  position: absolute;
  right: 0;
}

.emoji-icon {
  position: absolute;
  display: inline-block;
  left: 200px;
} */
</style>
<script>
import { mapState, mapActions } from 'pinia'
import { useSettingStore } from '../../stores/setting'

// import picker compopnent
import EmojiPicker from 'vue3-emoji-picker'

// import css
import 'vue3-emoji-picker/css'
export default {
  name: 'WellcomeMessageSettingView',
  components: {
    EmojiPicker,
  },
  data: () => ({
    message: '',
    emojiShow: false,
  }),
  computed: {
    ...mapState(useSettingStore, ['wellcomeMessage']),
  },
  methods: {
    ...mapActions(useSettingStore, ['getWellcomeMessage', 'saveWellcomeMessage']),
    clickHandler() {
      console.log('clickHandler')
      this.emojiShow ^= true
    },
    onSelectEmoji(emoji) {
      this.message += ' ' + emoji.i
      this.$refs.bar.focus()
      console.log(emoji)
      /*
    // result
    { 
        i: "😚", 
        n: ["kissing face"], 
        r: "1f61a", // with skin tone
        t: "neutral", // skin tone
        u: "1f61a" // without tone
    }
    */
    },
    saveHandler: async function () {
      console.log('saveHandler => ', {
        message: this.message,
      })

      this.saveWellcomeMessage();
      //   let res = await axios.post(`/setting`, {
      //     key: 'wellcome_message',
      //     value: this.message
      //   });

      //   console.log({
      //   res
      // })
      // },
    },
  },
  async updated() {},
  async created() {
    // let res = await axios.get(`/setting/wellcome_message`);
    // console.log({
    //   res
    // })
    // if(res.data) {
    //   console.log({
    //   resData: res.data.data
    // })
    //   this.message = res.data['data']['value']
    // }else{
    //   this.message = ""
    // }
  },

  async mounted() {
    this.getWellcomeMessage();
  },
}
</script>
