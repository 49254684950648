export default (to, from, next) => {

    const auth = window.$cookies.get('jwt');
    console.log('guest', auth )
    if (auth) {
    console.log('auth' )

        next({ name: 'Home' });
    } else {
    console.log('no-auth' )

        next();
    }
}