import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import ConversationsView from "../views/ConversationsView.vue";
import ConversationView from "../views/ConversationView.vue";
import TemplatesView from "../views/TemplatesView.vue";
import TemplatesStatusView from "../views/TemplatesStatusView.vue";
import LoginView from "../views/auth/LoginView.vue";
import HomeSettingView from "../views/settings/HomeSettingView.vue";
import UsersSettingView from "../views/settings/UsersSettingView.vue";
import WellcomeMessageSettingView from "../views/settings/WellcomeMessageSettingView.vue";
import EventAutoMessageSettingView from "../views/settings/EventAutoMessageSettingView.vue";
import ImageTemplateMessageView from "../views/settings/ImageTemplateMessageView.vue";
import TemplateAssetsView from "../views/settings/TemplateAssetsView.vue";
import BlackListView from "../views/settings/BlackListView.vue"






import middleware from "./middleware";

const routes = [
  {
    path: "/login",
    name: "login",
    component: LoginView,
    beforeEnter: middleware.guest,
  },
  {
    path: "/",
    name: "home",
    component: HomeView,
    beforeEnter: middleware.auth,
  },
  {
    path: "/conversations",
    name: "conversations",
    component: ConversationsView,
    beforeEnter: middleware.auth,
    children:[
      {
        path: "/conversations/:id",
        name: "conversation",
        component: ConversationView,
      },
      { path: "/:pathMatch(.*)*", redirect: "/conversations" },
    ],
  },
  {
    path: "/templates",
    name: "templates",
    component: TemplatesView,
    beforeEnter: middleware.auth,
  },
  {
    path: "/templates-statistics",
    name: "templatesStatistics",
    component: TemplatesStatusView,
    beforeEnter: middleware.auth,
  },
  {
    path: "/settings",
    name: "settings",
    component: HomeSettingView,
    beforeEnter: middleware.auth,
    children:[
      {
        path: "/settings/users-setting",
        name: "users-setting",
        component: UsersSettingView,
      },
      {
        path: "/settings/black-list-setting",
        name: "black-list-setting",
        component: BlackListView,
      },
      {
        path: "/settings/wellcome-message-setting",
        name: "wellcome-message-setting",
        component: WellcomeMessageSettingView,
      },
      {
        path: "/settings/auto-message-setting",
        name: "auto-message-setting",
        component: EventAutoMessageSettingView,
      },
      {
        path: "/settings/image-template-setting",
        name: "image-templat-setting",
        component: ImageTemplateMessageView,
      },
      {
        path: "/settings/template-assets-setting",
        name: "template-assets-setting",
        component: TemplateAssetsView,
      },
      { path: "/:pathMatch(.*)*", redirect: "/settings/users-setting" },
    ],
  },
  { path: "/:pathMatch(.*)*", redirect: { name: "home" } },
];

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_URL),
  routes,
  base: "/",

});

export default router;
