import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

// Pinia config
import { createPinia } from "pinia";

// Emitter config 
import mitt from "mitt";
const Emitter = mitt();
// Vuetify
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import "@mdi/font/css/materialdesignicons.css";

import VueCookies from 'vue-cookies'

// import { VDatePicker } from 'vuetify/labs/VPicker'

// export default createVuetify({
//   components: {
//     VDatePicker,
//   },
// })

// components.VDatePicker =VDatePicker;

const vuetify = createVuetify({
  components: {
    ...components,
        // VDatePicker,
      },
  directives,
});

createApp(App).use(VueCookies).use(vuetify).provide('Emitter', Emitter).use(createPinia()).use(router).mount("#app");
