<template>
  <v-row style="margin: 20px auto">
    <v-col cols="12">


      <v-row>
        <v-col cols="3" sm="3">
          <v-select update:modelValue="true" clearable :menu-props="{ maxHeight: 'unset' }" chips label="template"
            :items="Object.keys(this.templates)" v-model="template" @update:modelValue="templateChanged"></v-select>
        </v-col>

        <v-col cols="7" sm="7">
        
          <v-row>
            <v-col cols="6" sm="6">
              <div>
                <v-text-field class="date-picker" @click="from_menu ^= true" :value="getDate(from_data, 'from')" :active="true" readonly variant="outlined"
                label="From" append-icon="mdi-calendar-filter"></v-text-field>

              <v-date-picker v-if="from_menu" @update:modelValue="from_menu = false"  v-model="from_data" text="test"
                height="530" position="absolute"></v-date-picker>
              </div>
            </v-col>  
            <v-col cols="6" sm="6">
              <div>
                <v-text-field class="date-picker" @click="to_menu ^= true" :value="getDate(from_data, 'to')" :active="true" readonly variant="outlined"
                label="To" append-icon="mdi-calendar-filter"></v-text-field>

              <v-date-picker v-if="to_menu" @update:modelValue="to_menu = false"  v-model="from_data" text="test"
                height="530" position="absolute"></v-date-picker>
              </div>
            </v-col>  
          </v-row>

          
        </v-col>

        <v-col cols="2" sm="2">
          <v-btn 
          :disabled=" !template || !to || !from"
          @click="sendToUserHandler" style="margin-bottom: 15px"> Filter </v-btn>
        </v-col>
      </v-row>


    </v-col>
  </v-row>

  <v-divider class="border-opacity-75"></v-divider>
  <div>
    <v-table fixed-header fixed-footer height="600px">
      <thead>
        <tr>
          <!-- <th class="text-left">
            Template Name
          </th> -->
          <th class="text-left">
            From
          </th>
          <th class="text-left">
            To
          </th>
          <th class="text-left">
            sent
          </th>
          <th class="text-left">
            delivered
          </th>
          <th class="text-left">
            read
          </th>

        </tr>
      </thead>
      <tbody v-if="templateStatus.length > 0">
        <tr v-for="item in templateStatus" :key="item._id">
          <!-- <td>{{ item.template_name }}</td> -->
          <td>{{ momentFilter(new Date(item.start * 1000)) }}</td>
          <td>{{ momentFilter(new Date(item.end * 1000)) }}</td>

          <td>{{ item.sent }}</td>
          <td>{{ item.delivered }}</td>
          <td>{{ item.delivered }}</td>
          <!-- <td>{{ item.failed }}</td> -->
        </tr>
        
      </tbody>
      <tbody  class="no-data-body" v-else>
        <p class="text-center no-data-content">
          no data
        </p>
      </tbody>

    </v-table>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useUserStore } from '../stores/user'
import { useTemplateStore } from '../stores/template'
import { useGlobalStore } from '../stores/global'

import moment from 'moment'

export default {
  name: 'TemplatesStatusView',
  components: {},
  data: () => ({
    picker: '2018-03-02',
    from_date: null,
    to_date: null,

    from: null,
    to: null,

    datesFormate: [],

    from_menu: false,
    to_menu: false,

    url: '',
    selected: [],
    // products: [],
    select: [],
    items: [],
    isErrorToSend: true,
    show: false,
    template: null,
    template_id: null,

    templatesData: [],
    variables: [],
    header: [],
    errors: [],
    headerErrors: [],
    excelUsers: [],
    platform: 'whatsapp',
    usersAddedFrom: ['all', 'excel_sheet', 'url', 'api', 'conversation'],
    usersFrom: {
      all: [],
      excel_sheet: [],
      url: [],
      api: [],
      conversation: [],
    },
    userFrom: null,
    currentUsersList: [],
  }),
  computed: {
    ...mapState(useUserStore, [
      'userList',
      'user',
      'admin',
      'filterUsers',
      'instagramUsers',
      'whatsappUsers',
      'gerUsersAddedFrom',
    ]),
    ...mapState(useTemplateStore, ['templates', 'products', 'templatesKeys', 'templateStatus']),
    likesAllUsers() {
      return this.select.length === this.gerUsersAddedFrom(this.userFrom).length
    },
    likesSomeUsers() {
      return this.select.length > 0
    },
  },
  methods: {
    ...mapActions(useUserStore, ['getUsers', 'sendTemplateMessage']),
    ...mapActions(useTemplateStore, ['getTemplates', 'getTemplateStatus']),
    ...mapActions(useGlobalStore, ['setAlertMessage']),

    cancelHandler() {
      console.log('cancelHandler')
      this.menu = false;
      // this.menuFlag = false
    },
    okHandler() {
      console.log('okHandler', this.dates)
      this.menu = false;
      this.getDate(this.dates);
    },
   
    templateChanged(value) {
      console.log('template changed - ', value, this.templates)
      this.template_id = this.templates[value]['id'];
    },

    async sendToUserHandler() {
      console.log(this.template_id, this.from, this.to)

      const res = await this.getTemplateStatus({
        template: this.template_id,
        start: this.from,
        end: this.to
      });

      if(!res.success){
        this.setAlertMessage( {
          display: true,
          text: res.message,
          class: "error",
        })
      }
      console.log({
        res
      })
    },
    momentFilter: function (date) {
      return moment(date).format('yy-MM-DD')
    },
    getDate(date, type) {
      let res = '';
      if(date){
        console.log('getDate', {date});
        res = new Date(date);
        console.log({ date: Math.floor(res.getTime() / 1000) }) 
        if(type === 'from')
          this.from = Math.floor(res.getTime() / 1000)
        if(type === 'to')
          this.to =  Math.floor(res.getTime() / 1000)

        res = this.momentFilter(res);

      }     
      return res;
    },

    async getRangeOfDates(startDate, endDate) {

      let start = new Date(new Date(startDate).setUTCHours(0, 0, 0, 0));
      let end = new Date(new Date(endDate).setUTCHours(0, 0, 0, 0));

      start.setDate(start.getDate() + 1);
      end.setDate(end.getDate() + 1);


      console.log({
        start, end
      })
      const date = new Date(start.getTime());

      const dates = [];

      while (date <= end) {
        dates.push(new Date(date));
        date.setDate(date.getDate() + 1);
      }

      return dates;



    },
    usersAddedFromHandler(value) {
      console.log({ value }, value === null)
      if (value === null) {
        this.select = []
        this.currentUsersList = []
      } else {
        this.currentUsersList = this.gerUsersAddedFrom(value)
        // switch (value) {
        //   case 'all':
        //     this.currentUsersList = this.gerUsersAddedFrom('all')
        //     break
        //   case 'excel_sheet':
        //     this.currentUsersList = this.gerUsersAddedFrom('excel_sheet')
        //     break
        //   case 'url':
        //     this.currentUsersList = this.gerUsersAddedFrom('url')
        //     break
        //   case 'api':
        //     this.currentUsersList = this.gerUsersAddedFrom('api')
        //     break
        //   case 'conversation':
        //     this.currentUsersList = this.gerUsersAddedFrom('conversation')
        //     break
        // }
      }
    },

    saveHandler() {
      console.log('saveHandler', {
        val: this.dates
      })
    }
  },

  async mounted() {
    console.log('templates | mounted')
    document.title = 'Templates'
    await this.getTemplates()
    await this.getUsers()
  },
}
</script>

<style scoped>
.template-container {
  height: 80vh;
  overflow-x: auto;
}

/* .products-container {
  height: 350px !important;
  overflow: auto;
} */

.no-data-body{
  position: relative;
}
.no-data-content{
  position: absolute;
    right: 50%;
}

.v-date-picker{
  z-index: 90 !important;
    top: 153px !important;
}

.text-danger {
  color: #f44336;
}
</style>
