<template>
  <v-container>
    <v-table fixed-header fixed-footer height="525px">
      <thead>
        <tr>
          <!-- <th class="text-left">
            Template Name
          </th> -->
          <th class="text-left">
            Created At
          </th>
          <th class="text-left">
            Phone Number
          </th>
          <th class="text-left">
            Action
          </th>
        </tr>
      </thead>
      <tbody v-if="blackList.data.length > 0">
        <tr v-for="item in blackList.data" :key="item._id">
          <!-- <td>{{ item.template_name }}</td> -->
          <td>{{ momentFilter(new Date(item.createdAt)) }}</td>
          <td>{{ item.phoneNumber }}</td>
          <td>
              <v-icon title="logout" @click="clickHandler(item._id)" icon="mdi-delete" color="red"></v-icon>
          </td>
        </tr>

      </tbody>
      <tbody class="no-data-body" v-else>
        <p class="text-center no-data-content">
          no data
        </p>
      </tbody>
    </v-table>
    <v-pagination v-model="page" :length="blackList.next" rounded="circle" @prev="getData" @next="getData"
      @update:modelValue="getData"></v-pagination>

    <v-dialog v-model="dialog" persistent width="auto">
      <v-card>
        <v-card-title class="text-h5"> Black List </v-card-title>
        <v-card-text>Are you sure you want to remove this phone from black list?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green-darken-1" variant="text" @click="deleteHandler"> Yes </v-btn>
          <v-btn color="green-darken-1" variant="text" @click="dialog = false"> No </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style scoped>
.card-container {
  box-shadow: none;
  height: 80vh;
}

.window-container {
  padding: 20px;
}
</style>

<script>
import moment from 'moment'

import { mapState, mapActions } from 'pinia'
import { useGlobalStore } from '../../stores/global'
import { useUserStore } from '../../stores/user'

export default {
  name: 'BlackListView',
  components: {},

  data: () => ({
    page: 1,
    dialog: false,
    id: null
  }),
  computed: {
    ...mapState(useGlobalStore, ['notifications']),
    ...mapState(useUserStore, ['blackList']),

  },
  methods: {
    ...mapActions(useGlobalStore, ['setNotify', 'setNotifications', 'setAlertMessage', 'appendNotifications']),
    ...mapActions(useUserStore, ['getBlackList', 'deleteFromBlackList']),
    momentFilter: function (date) {
      return moment(date).format('yy-MM-DD HH:mm')
    },
    getData() {
      this.getBlackList(this.page);
    },
    clickHandler(id){
      this.id = id;
      this.dialog = true;
    },
    async deleteHandler() {
      this.dialog = false;
      let res = await this.deleteFromBlackList( this.id);
      console.log({
        id: this.id,
        res
      })
      if(res.success)
      this.setAlertMessage( {
          display: true,
          text: res.message,
          class: "success",
        })
    }
  },

  async updated() { },
  async created() {
    console.log('blackList | created')
  },
  async mounted() {
    console.log('blackList');
    this.getBlackList(1);
  },
}
</script>
