<template>
  <!-- {{ alertMessage }} -->
  <v-snackbar :color="alertMessage?.class" v-if=getAlertMessageDisplay v-model="snackbar" location="top right" :timeout="alertMessage?.timeout">
    {{ alertMessage?.text }}
    <template v-slot:actions>
      <v-btn color="red" variant="text" @click="snackbar = false">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>


<script>

import { mapState, mapActions } from 'pinia'

import { useGlobalStore } from '../../stores/global'


export default {
  name: "notification-component",
  data: () => ({
      // snackbar: false,
      text: 'My timeout is set to 2000.',
      timeout: 2000,
    }),
  computed: {
    ...mapState( useGlobalStore , [ 'alertMessage', 'getAlertMessageDisplay']),
    snackbar: {
      get() {
        console.log("get ",  this.alertMessage.display)
        return this.alertMessage.display;
      },
      set() {
        let alertMessage = this.alertMessage;
        alertMessage.display = false
        this.setAlertMessage(alertMessage);
        // this.$store.commit("SET_NOTIFICATION", {
        //   display: false
        // })
      }
    }
  },
  methods: {
    ...mapActions(useGlobalStore, ['setAlertMessage']),
  }
};
</script>
