import api from "./api";

export default {
  login(params) {
    return api.post('auth/login', params);
  },

  forgetPassword(params) {
    return api.post('auth/forget-password', params);
  },
  
  logout() {
    return api.post('auth/logout');
  },
};
