<template>
  <v-container>
    <v-card class="card-container">
      <v-tabs v-model="tab" color="deep-purple-accent-4" align-tabs="center">
        <v-tab value="one">Import From Url</v-tab>
        <v-tab value="two">Import From Excel</v-tab>
        <v-tab value="three">Rest Users</v-tab>
      </v-tabs>

      <!-- <v-card-text> -->
      <v-window v-model="tab" class="window-container">
        <v-window-item value="one">
          <v-btn @click="importUsersFromUrlHandler"> import </v-btn>
        </v-window-item>

        <v-window-item value="two">
          <v-file-input
            @change="onChange"
            @click:clear="onClear"
            show-size
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            label="File input"
          ></v-file-input>

          <div>
            Number of users: {{ fileInfo.usersNumber }},
            <!-- <template> -->
            <div>
              <span>existedCount</span>
              <v-progress-linear
                v-model="importExcelStatus.existedCount"
                :max="fileInfo.usersNumber"
                color="blue-grey"
                height="25"
              >
                <!-- <template v-slot:default="{ value }"> -->
                <strong>{{
                  importExcelStatus.existedCount ? importExcelStatus.existedCount : 0
                }}</strong>
                <!-- </template> -->
              </v-progress-linear>

              <br />
              <span>createdCount:</span>

              <v-progress-linear
                v-model="importExcelStatus.createdCount"
                :max="fileInfo.usersNumber"
                color="blue-grey"
                height="25"
              >
                <!-- <template v-slot:default="{ value }"> -->
                <strong>{{
                  importExcelStatus.createdCount ? importExcelStatus.createdCount : 0
                }}</strong>

                <!-- </template> -->
              </v-progress-linear>
              <br />
            </div>
            <!-- </template> -->
          </div>

          <v-btn @click="importUsersFromExcelHandler" :disabled="excelData.length == 0">
            import
          </v-btn>
        </v-window-item>

        <v-window-item value="three">
          <h2>Reset Users [ {{ userFrom }} ]</h2>
          <v-row>
            <v-col cols="8">
              <v-select
                clearable
                chips
                label="Users Added From"
                :items="usersFrom"
                v-model="userFrom"
              ></v-select>
            </v-col>
            <v-col cols="3">
              <v-dialog v-model="dialog" persistent width="auto">
                <template v-slot:activator="{ props }">
                  <v-btn
                    color="primary"
                    v-bind="props"
                    style="width: fit-content"
                    :disabled="userFrom == null"
                  >
                    Reset
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title class="text-h5">
                    Are you sure you want to reset {{ userFrom }} users ?
                  </v-card-title>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green-darken-1" variant="text" @click="dialog = false">
                      No
                    </v-btn>
                    <v-btn color="green-darken-1" variant="text" @click="confirmHandler">
                      Yes
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
        </v-window-item>
      </v-window>
      <!-- </v-card-text> -->
    </v-card>
  </v-container>
</template>

<style scoped>
.card-container {
  box-shadow: none;
  height: 80vh;
}
.window-container {
  padding: 20px;
}
</style>

<script>
import repository from '../../api/repository'

import * as XLSX from 'xlsx'
import { mapState, mapActions } from 'pinia'
import { useGlobalStore } from '../../stores/global'
import { useUserStore } from '../../stores/user'

export default {
  name: 'UsersSettingView',
  components: {},

  data: () => ({
    tab: null,
    dialog: false,
    usersFrom: ['all', 'excel_sheet', 'url', 'api', 'conversation'],
    userFrom: null,
    responseMsgs: [],
    file: null,
    fileInfo: {
      size: 0,
      usersNumber: 0,
    },
    importExcelStatus: {
      existedCount: 0,
      createdCount: 0,
    },
    importUrlStatus: {
      existedCount: 0,
      createdCount: 0,
    },
    excelData: [],
  }),
  computed: {
    ...mapState(useGlobalStore, ['notifications']),
  },
  methods: {
    ...mapActions(useGlobalStore, ['setNotify', 'setNotifications', 'appendNotifications']),
    ...mapActions(useUserStore, ['setIsUsersUpdated']),

    async importUsersFromUrlHandler() {
      const res = await repository.create('users/import-url', {
        url: 'https://smarterp.top/api/v1.0/customers/index?token=YjMyY2JjZjc5ZDUxOTYxMTNiNDc0MTNjYmRiZWMzMjk=&company=demo_oman',
      })
      this.setNotifications(res.data.data.messages)
      this.importUrlStatus.existedCount += res.data.data.existedCount
      this.importUrlStatus.createdCount += res.data.data.createdCount

      if (this.importUrlStatus.createdCount > 0) {
        this.setIsUsersUpdated()
      }
      this.setNotify()

      console.log({
        res,
      })
    },

    async importUsersFromExcelHandler() {
      const chunkSize = 100
      for (let idx = 0; idx < this.excelData.length; idx += chunkSize) {
        const chunk = this.excelData.slice(idx, idx + chunkSize)
        console.log({
          chunk,
          idx,
          t: idx === 0,
        })

        const res = await repository.create('users/import', { data: chunk })

        // this.responseMsgs.push(...res.data.data)

        // await this.$store.dispatch('SET_LOGGER', this.responseMsgs)
        if (idx === 0) {
          console.log('setNotifications')
          this.setNotifications(res.data.data.messages)
        } else {
          console.log('appendNotifications')

          this.appendNotifications(res.data.data.messages)
        }
        this.importExcelStatus.existedCount += res.data.data.existedCount
        this.importExcelStatus.createdCount += res.data.data.createdCount

        if (this.importExcelStatus.createdCount > 0) {
          this.setIsUsersUpdated()
        }

        this.setNotify()

        console.log({
          res,
        })
      }
    },
    onClear() {
      console.log('onClear')
      this.fileInfo.usersNumber = 0
      this.excelData = []
    },
    async onChange(event) {
      this.file = event.target.files ? event.target.files[0] : null
      if (this.file) {
        console.log({
          wb: this.file,
        })
        this.fileInfo.size = this.file.size
        const reader = new FileReader()

        reader.onload = async (e) => {
          /* Parse data */
          const bstr = e.target.result
          const wb = XLSX.read(bstr, { type: 'binary' })
          /* Get first worksheet */
          const wsname = wb.SheetNames[0]
          const ws = wb.Sheets[wsname]
          /* Convert array of arrays */
          this.excelData = XLSX.utils.sheet_to_json(ws, { header: 1 })
          this.excelData.shift()
          console.log({
            data: this.excelData,
            wb: this.file,
          })

          this.excelData.forEach((item) => {
            console.log({ is0: item[0] !== undefined, is1: item[1] !== undefined })
          })
          this.excelData = this.excelData.filter((item) => {
            return item[0] !== undefined && item[1] !== undefined
          })
          this.fileInfo.usersNumber = this.excelData.length
        }
        reader.readAsBinaryString(this.file)
        this.file = null
      }
    },
    async confirmHandler() {
      this.dialog = false
      if (this.userFrom) {
        const res = await repository.create('users/reset', { added_from: this.userFrom })
        // this.responseMsgs.push(res.data.data)
        // await this.$store.dispatch('SET_LOGGER', this.responseMsgs)
        this.setNotifications([res.data.data])
        this.setIsUsersUpdated()
        console.log({ res })
        this.setNotify()
      }
    },
  },

  async updated() {},
  async created() {
    console.log('users | created')
  },
  async mounted() {
    console.log('users')
  },
}
</script>
