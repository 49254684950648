<template>
  <!-- <div class="layout"> -->
    <v-layout full-height="true">
      <AppDrawer />
      <v-main>
        <slot></slot>
      </v-main>
      <AppNav />
      <!-- <AppFooter /> -->
    </v-layout>
  <!-- </div>/ -->
</template>

<script>
import AppNav from "./AppNav";
import AppDrawer from "./AppDrawer";
// import AppFooter from "./AppFooter";
export default {
  components: {
    AppNav,
    // AppFooter,
    AppDrawer,
  },
  data: () => ({
    drawer: false,
  }),
};
</script>
