import { defineStore } from 'pinia'
import repository from "../api/repository";

export const useTemplateStore = defineStore('template', {
  state: () => {
    return {
      products: [],
      // templates: [],
      templateStatus: [],
      data: null,
      isTemplatesUpdated: true
    }
  },
  getters: {
    products: (state) => {
      return state.data ? state.data.products : [];
    },
    templates: (state) => {
      return state.data ? state.data.templates : {};
    },
    templatesKeys: (state) => {
      return state.data ? Object.keys(state.data.templates) : [];
    },
    templatesWithAttachments: (state) => {

      let resData = {};
      if(state.data){
        if( state.data.templates){
          for (const key in state.data.templates) {

            console.log(`${key}: ${state.data.templates[key]}`);
            if(state.data.templates[key]['isAssetsNedded']){
              resData[key] = state.data.templates[key]
            }
        }
        }
       
      }

    console.log({
      resData
    })
     
      return resData;
    },
  },
  actions: {
    async getTemplates() {
      if(!this.isTemplatesUpdated){
        console.log('templates already Loaded')
      }else{
        const res = await repository.index(`templates`);
        console.log({res})
        this.data = res.data;

        console.log({
        templatesKeys: Object.keys(this.data.templates)
      })
        // this.templates = res.data.data.templates;
        this.isTemplatesUpdated = false;
      }
     
    },
    async getTemplateStatus(filter) {
      let response = {};
      try{
        const res = await repository.create(`messages/get-template-status`, filter);
        this.templateStatus = res?.data?.data;
        console.log({res});
        response = {
          success: res?.data?.success,
          message: res?.data?.message,
        }

      }catch(e){
        console.log({e});
        response = {
          success: e?.response?.data?.success,
          message: e?.response?.data?.message,
        }
      }
       
      return response;
    }
  }
})
