import api from "./api";

export default {
  get(key) {
    return api.get(`setting/${key}`);
  },

  save(data) {
    return api.post(`setting`, data);
  },

  upload(form_data) {
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    return api.post(`setting/attachments`, form_data, config);
  },
  saveLocation(data) {
    return api.post(`setting/attachments`, data,);
  },
};
