import { defineStore } from 'pinia'
import repository from "../api/repository";

export const useDashboardStore = defineStore('dashboard', {
  state: () => {
    return {
      data: null,
      isNotify: false,
    }
  }, 
  getters: {
    templatsStatus(state){
      return state?.data?.templates?.count
    },
    templatsData(state){
      return state?.data?.templates?.templatesData
    },
    whatsappUsers(state){
      return state?.data?.users?.whatsapp
    },
    instagramUsers(state){
      return state?.data?.users?.instagram
    }
  },
  actions: {
    async getDashboardData() {
      if(this.data){
        console.log('dashboard data rendered before')
      }else{
        const res = await repository.index('dashboard');
        this.data = res?.data;
      }
      
    }
  }
})
