<template>
  <v-container>
    <v-col>

      <!-- <input type="file" @change="onFileChange" accept="image/*" /> -->

      <v-file-input
        v-model="image"
        type="file"
        class="input"
        label="Upload image"
        hint="Add a picture of your image template"
        accept="image/*"
        outlined
        dense
        @change="onFileChange"
        @click:clear="onClear"
      />
    </v-col>
    <v-col>
      <v-btn @click="onSave" :disabled="file == null">
          save
        </v-btn>
    </v-col>
    <v-col>
      <h4>Preview</h4>
      <v-img :src="templateImage.data" style="border: 1px dashed #ccc; height: 250px" />
    </v-col>
  </v-container>
</template>

<style scoped>
.text-area-container {
  max-height: 250px;
  overflow-y: auto;
}

.emoji-container {
  position: relative;
}

.emoji-container .emoji {
  position: absolute;
  right: 0;
}

.emoji-icon {
  position: absolute;
  display: inline-block;
  left: 200px;
}
</style>

<script>
import { mapState, mapActions } from 'pinia'
import { useSettingStore } from '../../stores/setting'

export default {
  name: 'ImageTemplateMessageView',
  components: {},
  data: () => ({
    file: null,
    responseMsgs: [],
    image: undefined,
    // to save image url
    imageUrl: '',
  }),
  computed: {
    ...mapState(useSettingStore, ['templateImage']),
  },
  methods: {
    ...mapActions(useSettingStore, ['getTemplateImage', 'setTemplateImage', 'saveTemplateImage']),
    async onSave() {
      console.log({
        file: this.file,
      })

      const form_data = new FormData();
      form_data.append("file", this.file);
      form_data.append("key", "template_image");
      await this.saveTemplateImage(form_data);
      // const form_data = new FormData();
      // form_data.append("file", this.file);
      // const config = {
      //   headers: {
      //     'content-type': 'multipart/form-data'
      //   }
      // }

      // const res = await axios.post('messages/upload-image-template', form_data, config);

      // this.responseMsgs.push(res.data.message)

      // await this.$store.dispatch("SET_LOGGER", this.responseMsgs);
      // console.log("sendFilesHandler ==> ", { res });
    },
    onClear(){
      this.file = null;
      this.imageUrl = ''
    },
    createImage(file) {
      const reader = new FileReader()

      reader.onload = (e) => {
        this.imageUrl = e.target.result
        this.setTemplateImage(e.target.result)
      }
      reader.readAsDataURL(file.target.files[0])
    },
    onFileChange(event) {
      if (!event) {
        return
      }
      this.file = event.target.files ? event.target.files[0] : null

      console.log(this.file)
      this.createImage(event)
    },
  },
  async updated() {},
  // async created() {
  //   let res = await axios.get(`/setting/wellcome_message`);

  //   console.log({
  //     res
  //   })

  //   if (res.data) {
  //     console.log({
  //       resData: res.data.data
  //     })
  //     this.message = res.data['data']['value']
  //   } else {
  //     this.message = ""
  //   }
  // },

  async mounted() {
    this.getTemplateImage();
    // axios.get("", {
    //   headers: {
    //     Authorization: "Bearer ",
    //   },
    // });
    // let res = await axios.post(`/messages/get-image`, {img_url:'https://lookaside.fbsbx.com/whatsapp_business/attachments/?mid=3382061442055836&ext=1688910872&hash=ATsi0JjbwA8tFBqECGqZ1PSzsbo_eUaieEJWzUzbD9tTbQ'});
    // console.log({res})
  },
}
</script>
