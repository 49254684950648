<template>
  <section class="loading">
    <span class="loading__anim"></span>
    <span class="loadWords">Loading</span>
  </section>
</template>

<script>
export default {
  name: 'loading-component',
  data() {
    return {}
  },

  mounted() {},
}
</script>

<style scoped>
body {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.loading {
  z-index: 1000000;
  top: 0;
  background-color: #00000027;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  line-height: 1.4;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
}

.loadWords {
  font-weight: normal;
  font-size: 0.9rem;
  color: #973b96;
  margin: 0.6rem 0 2rem 0;
  display: block;
}

.loading__anim {
  width: 35px;
  height: 35px;
  border: 5px solid rgba(189, 189, 189, 0.25);
  border-left-color: #f89926;
  border-top-color: #f89926;
  border-radius: 50%;
  display: inline-block;
  animation: rotate 600ms infinite linear;
}

@keyframes rotate {
  to {
    transform: rotate(1turn);
  }
}
</style>
