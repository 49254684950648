import axios from "axios";

import { useGlobalStore } from '../stores/global'

const baseApiUrl = process.env.VUE_APP_BACK_END_BASE_API_URL;
const apiVersion = process.env.VUE_APP_BACK_END_BASE_API_VERSION;

const baseURL = `${baseApiUrl}/${apiVersion}/`;

let instance = axios.create({
    withCredentials: true,
    baseURL
});



instance.interceptors.request.use(async request => {
    // const globalStore = useGlobalStore();
    
    request.headers['Accept']       = 'application/json';
    request.headers['Content-Type'] = request.headers['Content-Type'] ? request.headers['Content-Type'] : 'application/json';
    request.headers['Authorization'] =  `Bearer ${  window.$cookies.get('jwt') ? window.$cookies.get('jwt') : null}`
    console.log({
        request,
        headers: request.headers['Content-Type']
    })
    console.log('loadding start');
    // store.dispatch("setIsLoading", true);
    // globalStore.enableLoading();
    return request;
});

instance.interceptors.response.use(
async response => { 
    // const globalStore = useGlobalStore();

    console.log('loadding end');
    // store.dispatch("setIsLoading", false);
    // globalStore.disableLoading();

    return response; 

},
error => {
    const globalStore = useGlobalStore();
    // store.dispatch("setIsLoading", false);
    globalStore.disableLoading();

        if (error?.response?.status === 401) {
            window.$cookies.remove('jwt')
            window.location.reload();
        }

    // console.log('loadding end');

        return Promise.reject(error);
    }
);

export default instance;
