<template>
  <template v-if="!isMobile">
    <Applayout v-if="this.$route.name !== 'login'">
      <router-view />
  <Notification />

    </Applayout>
    <template v-else>
      <router-view />
    </template>
    <Loading v-if="isLoading"></Loading>
  </template>
  <template v-else>
    <p>Please rotate</p>
  </template>

</template>
<script>
import { mapState, mapActions } from 'pinia'
import { useGlobalStore } from './stores/global'
import { useUserStore } from './stores/user'
import Applayout from '@/components/global/AppLayout.vue'
import Loading from '@/components/global/Loading.vue'
import Notification from '@/components/global/Notification.vue'


export default {
  components: {
    Applayout,
    Loading,
    Notification
  },

  data: () => ({
    isMobile: false,
  }),

  computed: {
    ...mapState(useGlobalStore, ['isLoading']),
  },
  async updated() {
    console.log('App | updated', {
      route: this.$route,
    })

    // if(this.$route.name != 'login')
    //   this.getAdmin();
  },
  async mounted() {},
  created() {
    // this.isMobile = screen.width <= 760
    // window.addEventListener('orientationchange', () => {
    //   this.isMobile = screen.width <= 760

    //   console.log({
    //     isMobile: this.isMobile,
    //   })
    // })
  },

  methods: {
    ...mapActions(useUserStore, ['getAdmin']),
  },
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  overflow: hidden;
}
.v-text-field .v-input__details {
  display: none !important;
}
nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fcf9f9;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #20509a;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #153464;
}
// @media screen and (min-aspect-ratio: 13/9) {
//   html {
//     transform: rotate(-90deg);
//     transform-origin: left top;
//     width: 100vh;
//     overflow-x: hidden;
//     position: absolute;
//     top: 100%;
//     left: 0;
//   }
//   .bw-dashboard {
//     height: inherit;
//   }
// }
// @media screen and (min-width: 1292px) {
//   html {
//     transform: none;
//     transform-origin: none;
//     width: 100%;
//     overflow-x: none;
//     position: relative;
//   }
//   .bw-dashboard {
//     height: 100vh;
//   }
// }
</style>
