import { defineStore } from 'pinia'
import setting from "../api/setting";

export const useSettingStore = defineStore('setting', {
  state: () => {
    return {
     wellcomeMessage:{
      data: null,
      isChanged: true
     },
     instagramAutoMessage:{
      data: null,
      isChanged: true
     },
     templateImage:{
      data: null,
      isChanged: true
     }
    }
  }, 
  getters: {
   
  },
  actions: {
    async getWellcomeMessage() {
      if(!this.wellcomeMessage.isChanged){
        console.log('Wellcome Message already Loaded')
      }else{
        const res = await setting.get('wellcome_message');
        this.wellcomeMessage.data = res.data.data.value ? res.data.data.value : '';
        this.wellcomeMessage.isChanged = false;
      }
    },
    async saveWellcomeMessage() {
      
        const res = await setting.save({
          key: 'wellcome_message',
          value: this.wellcomeMessage.data
        });
        console.log({
          res
        })
        // this.wellcomeMessage.data = res.data.data;
        // this.wellcomeMessage.isChanged = true;
      
    },
    async getInstagramAutoMessage() {
      if(!this.instagramAutoMessage.isChanged){
        console.log('Instagram Auto Message already Loaded')
      }else{
        const res = await setting.get('instagram_auto_message');
        this.instagramAutoMessage.data = res.data.data.value ? res.data.data.value : '';
        this.instagramAutoMessage.isChanged = false;
      }
    },
    async saveInstagramAutoMessage() {
      
        const res = await setting.save({
          key: 'instagram_auto_message',
          value: this.instagramAutoMessage.data
        });
        console.log({
          res
        })
        // this.wellcomeMessage.isChanged = true;
    },
    async getTemplateImage() {
      if(!this.templateImage.isChanged){
        console.log('templateImage already Loaded')
      }else{
        const res = await setting.get('template_image');
        this.templateImage.data = res.data.data.path ? res.data.data.path : '';
        this.templateImage.isChanged = false;
      }
    },
    async setTemplateImage(path) {
      
        this.templateImage.data = path;
       
    },
    async saveTemplateImage(form_data) {
      
      console.log({
        form_data
      })
       
      // form_data.append("key", "wellcome_message");


      

      // const res = await axios.post('messages/upload-image-template', form_data, config);

      // this.responseMsgs.push(res.data.message)

      // await this.$store.dispatch("SET_LOGGER", this.responseMsgs);
      // console.log("sendFilesHandler ==> ", { res });
        const res = await setting.upload(
          form_data,
         
      );
        console.log({
          res
        })
        this.templateImage.data = res.data.data.path;
        this.templateImage.isChanged = true;
      
    },
    async saveTemplateAssets(data, type) {
      
      let res = null;
      console.log({
        data
      })
      if(type !== 'LOCATION')
        res = await setting.upload(data);
      else
         res = await setting.saveLocation(data);
      console.log({
        res
      })
      return res.data.data;
    
    },
  }
})
