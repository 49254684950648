export default (to, from, next) => {
    const auth = window.$cookies.get('jwt');
    console.log('auth', auth )

    if (auth) {
    console.log('auth' )
        
        next();
    } else {
    console.log('no-auth' )

        next({ name: 'login' });
    }
}
