import { defineStore } from 'pinia'

export const useGlobalStore = defineStore('global', {
  state: () => {
    return {
      isLoading: false,
      isNotify: false,
      notifications: [],
      alertMessageDisplay: false,
      alertMessage: {
        display: false,
        text: "Notification placeholder text",
        timeout: 3000,
        class: "success"
      },
    }
  }, 
  getters: {
    getNotifications(state){
      return state.notifications;
    },
    alertMessage(state){
      console.log("alertMessage ", state.alertMessage)
      return state.alertMessage ?? {
        display: false,
        text: "Notification placeholder text",
        timeout: 10000,
        class: "success"
      };
    },
    getAlertMessageDisplay(state){
      console.log("alertMessage ", state.alertMessage)

      return  state.alertMessageDisplay
    }
  },
  actions: {
    disableLoading() {
      this.isLoading = false
    },
    enableLoading() {
      this.isLoading = true
    },
    setNotifications(data){
      this.notifications = data;
    },
    appendNotifications(data){
      this.notifications.push(data);
    },
    resetNotify() {
      this.isNotify = false
    },
    setNotify() {
      this.isNotify = true
    },
    setAlertMessage(data) {
      console.log({data})
     
      this.alertMessage.display = data.display;
      this.alertMessage.text = data.text;
      this.alertMessage.timeout = 3000;
      this.alertMessage.class = data.alertClass;
      this.alertMessageDisplay = data.display
      
    },
  }
})
