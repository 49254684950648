<template>
  <v-container>
    <v-col cols="4" sm="4">
      <v-select update:modelValue="true" clearable :menu-props="{ maxHeight: 'unset' }" chips label="template"
        :items="Object.keys(this.templatesWithAttachments)" v-model="template" @click:clear="onClear"
        @update:modelValue="templateChanged"></v-select>
    </v-col>
    <v-col>
      <div class="template-container" v-if="Object.keys(this.templatesWithAttachments).includes(template)">
        <v-card class="mx-auto">
          <template v-for="(component, idx) in this.templatesWithAttachments[template]['components']" :key="idx">
            <template v-if="component.type === 'HEADER'">
              <v-file-input v-if="component.format === 'IMAGE'" v-model="image" type="file" class="input"
                label="Upload image" :rules="imageRules" hint="Add a picture of your template"
                accept="image/jpeg, image/png" outlined dense @change="onFileChange($event, 'image')"
                @click:clear="onClear" />

              <v-file-input v-else-if="component.format === 'VIDEO'" v-model="video" type="file" class="input"
                label="Upload video" :rules="videoRules" hint="Add a video of your template"
                accept="video/mp4, video/3gpp" outlined dense @change="onFileChange($event, 'video')"
                @click:clear="onClear" />

              <v-file-input v-else-if="component.format === 'DOCUMENT'" v-model="document" type="file" class="input"
                label="Upload document" :rules="documentRules" hint="Add a document of your template"
                :accept="this.validDocumentTypes" outlined dense @change="onFileChange($event, 'document')"
                @click:clear="onClear" />

              <v-file-input v-else-if="component.format === 'AUDIO'" v-model="document" type="file" class="input"
                label="Upload audio" :rules="audioRules" hint="Add a audio of your template"
                accept="audio/aac, audio/mp4, audio/amr, audio/mpeg, audio/ogg; codecs=opus" outlined dense
                @change="onFileChange" @click:clear="onClear" />
              <template v-else-if="component.format === 'LOCATION'">
                <v-container>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="location.latitude" :rules="latitudeRules" label="Latitude" required
                        dense></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-text-field v-model="location.longitude" :rules="longitudeRules" label="Longitude" hide-details
                        required></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-text-field v-model="location.name" label="Name" :rules="nameAddressRules"
                        required></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-textarea v-model="location.address" :rules="nameAddressRules" required label="Address">
                      </v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
              <v-col>
                <v-btn @click="onSave(component.format)" :disabled="component.format !== 'LOCATION'
                    ? file == null
                    : location.latitude === '' ||
                    location.longitude === '' ||
                    location.name === '' ||
                    location.address === ''
                  ">
                  save</v-btn>
              </v-col>
              <v-row>
                <v-col cols="6">
                  <h4>Existed Asset</h4>
                  <v-img v-if="component.format === 'IMAGE'"
                    :src="this.templatesWithAttachments[template]['assets']['path']"
                    style="border: 1px dashed #ccc; height: 250px" />

                  <video ref="existedVideoPreview" id="existedVideoPreview" v-else-if="component.format === 'VIDEO'"
                    width="320" height="240" controls>
                    <source :src="this.templatesWithAttachments[template]['assets']['path']" type="video/mp4" />

                    Your browser does not support the video tag.
                  </video>

                  <a v-else-if="component.format === 'DOCUMENT'"
                    :href="this.templatesWithAttachments[template]['assets']['path']">Document</a>

                  <template v-else-if="component.format === 'LOCATION'">
                    <v-text-field v-model="this.templatesWithAttachments[template]['assets'].latitude" readonly
                      label="Latitude"></v-text-field>
                    <v-text-field v-model="this.templatesWithAttachments[template]['assets'].longitude" readonly
                      label="Longitude"></v-text-field>
                    <v-text-field v-model="this.templatesWithAttachments[template]['assets'].name" readonly
                      label="Name"></v-text-field>
                    <v-textarea v-model="this.templatesWithAttachments[template]['assets'].address" readonly
                      label="Address">
                    </v-textarea>
                  </template>
                </v-col>

                <v-col cols="6">
                  <h4>Preview</h4>
                  <v-img v-if="component.format === 'IMAGE'" :src="previewUrl"
                    style="border: 1px dashed #ccc; height: 250px" />

                  <video ref="videoPreview" id="videoPreview" v-else-if="component.format === 'VIDEO'" width="320"
                    height="240" controls>
                    <source :src="previewUrl" type="video/mp4" />

                    Your browser does not support the video tag.
                  </video>

                  <a v-else-if="component.format === 'DOCUMENT'" :href="previewUrl">Document</a>

                  <template v-else-if="component.format === 'LOCATION'">
                    <v-text-field v-model="location.latitude" readonly label="Latitude"></v-text-field>
                    <v-text-field v-model="location.longitude" readonly label="Longitude"></v-text-field>
                    <v-text-field v-model="location.name" readonly label="Name"></v-text-field>
                    <v-textarea v-model="location.address" readonly label="Address"> </v-textarea>
                  </template>
                </v-col>
              </v-row>
            </template>
          </template>
        </v-card>
      </div>
    </v-col>
  </v-container>
</template>

<style scoped>
.text-area-container {
  max-height: 250px;
  overflow-y: auto;
}

.emoji-container {
  position: relative;
}

.emoji-container .emoji {
  position: absolute;
  right: 0;
}

.emoji-icon {
  position: absolute;
  display: inline-block;
  left: 200px;
}
</style>

<script>
import { mapState, mapActions } from 'pinia'
import { useSettingStore } from '../../stores/setting'
import { useTemplateStore } from '../../stores/template'
import { useGlobalStore } from '../../stores/global'

export default {
  name: 'TemplateAssetsView',
  components: {},
  data: () => ({
    previewUrl: null,
    file: null,
    filePreview: null,
    image: null,
    video: null,
    document: null,
    audio: null,

    validDocumentTypes: [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/wspolicy+xml',
      'application/x-compress',
      'application/zip',
      'application/postscript',
      'application/x-aim',
      'application/x-bcpio',
      'application/octet-stream',
      'text/html',
      'application/x-cdf',
      'application/pkix-cert',
      'application/java',
      'application/x-cpio',
      'application/x-csh',
      'text/css',
      'application/msword',
      'application/x-wais-source',
      'application/x-netcdf',
      'application/oda',
      'application/vnd.oasis.opendocument.database',
      'application/vnd.oasis.opendocument.chart',
      'application/vnd.oasis.opendocument.formula',
      'application/vnd.oasis.opendocument.graphics',
      'application/vnd.oasis.opendocument.image',
      'application/vnd.oasis.opendocument.text-master',
      'application/vnd.oasis.opendocument.presentation',
      'application/vnd.oasis.opendocument.spreadsheet',
      'application/vnd.oasis.opendocument.text',
      'application/vnd.oasis.opendocument.graphics-template',
      'application/vnd.oasis.opendocument.text-web',
      'application/vnd.oasis.opendocument.presentation-template',
      'application/vnd.oasis.opendocument.spreadsheet-template',
      'application/vnd.oasis.opendocument.text-template',
      'application/ogg',
      'application/xslt+xml',
      'application/vnd.mozilla.xul+xml',
      'application/vnd.visio',
      'text/vnd.wap.wml',
      'application/vnd.wap.wmlc',
      'text/vnd.wap.wm',
      'application/vnd.wap.wmlscriptc',
      'application/font-woff',
      'application/font-woff2',
      'application/xml-dtd',
      'application/x-dvi',
      'application/vnd.ms-fontobject',
      'text/x-setext',
      'application/x-gtar',
      'application/x-gzip',
      'application/x-hdf',
      'application/mac-binhex40',
      'text/x-component',
      'text/vnd.sun.j2me.app-descriptor',
      'application/java-archive',
      'text/x-java-source',
      'application/x-java-jnlp-file',
      'application/javascript',
      'text/plain',
      'application/json',
      'application/x-latex',
      'application/x-font-opentype',
      'application/annodex',
      'application/xspf+xml',
      'application/pdf',
      'application/vnd.ms-powerpoint',
      'application/vnd.rn-realmedia',
      'application/rtf',
      'text/richtext',
      'application/font-sfnt',
      'application/x-sh',
      'application/x-shar',
      'application/x-stuffit',
      'application/x-sv4cpio',
      'application/x-sv4crc',
      'application/x-shockwave-flash',
      'application/x-tar',
      'application/x-tcl',
      'application/x-tex',
      'application/x-texinfo',
      'text/tab-separated-values',
      'application/x-font-ttf',
      'application/x-ustar',
      'application/voicexml+xml',
      'application/xhtml+xml',
      'application/vnd.ms-excel',
      'application/xml',
      'text/troff',
      'application/mathml+xml',
      'application/x-mif',
    ],
    responseMsgs: [],
    // image: undefined,
    template: null,
    imageRules: [
      (value) => {
        return (
          !value ||
          !value.length ||
          value[0].size < 5000000 ||
          'Image size should be less than 5 MB!'
        )
      },
    ],
    location: {
      latitude: '',
      longitude: '',
      name: '',
      address: '',
    },

    videoRules: [
      (value) => {
        return (
          !value ||
          !value.length ||
          value[0].size < 16000000 ||
          'video size should be less than 16 MB!'
        )
      },
    ],
    latitudeRules: [
      (value) => !!value || 'Required.',
      (value) => {
        /* eslint-disable */
        const latitudeRegex = /^[-]?([0-8]?\d(\.\d{1,20})?|90(\.0{1,20})?)$/
       
        console.log({
          value: +value,
          val: value[0],
          isValid: latitudeRegex.test(value),
        })
        return latitudeRegex.test(value) || 'Error - eg'
      },
    ],

    //longitudeRules
    longitudeRules: [
      (value) => !!value || 'Required.',
      (value) => {
        /* eslint-disable */
        const longitudeRegex =/^[-]?((1[0-7]\d(\.\d{1,20})?)|([0-9]?\d(\.\d{1,20})?)|180(\.0{1,20})?)$/
       
        console.log({
          value: +value,
          val: value[0],
          isValid: longitudeRegex.test(value),
        })
        return longitudeRegex.test(value) || 'Error - eg'
      },
    ],
    nameAddressRules: [(value) => !!value || 'Required.'],

    documentRules: [
      (value) => {
        return (
          !value ||
          !value.length ||
          value[0].size < 100000000 ||
          'document size should be less than 100 MB!'
        )
      },
    ],
    audioRules: [
      (value) => {
        return (
          !value ||
          !value.length ||
          value[0].size < 16000000 ||
          'audio size should be less than 16 MB!'
        )
      },
    ],

    // to save image url
    imageUrl: '',
  }),
  computed: {
    ...mapState(useSettingStore, ['templateImage']),
    ...mapState(useTemplateStore, ['templatesWithAttachments']),
  },
  methods: {
    ...mapActions(useSettingStore, ['getTemplateImage', 'setTemplateImage', 'saveTemplateAssets']),
    ...mapActions(useTemplateStore, ['getTemplates']),
    ...mapActions(useGlobalStore, ['setAlertMessage']),

    async onSave(type) {
      console.log({
        type,
        file: this.file,
        temp: this.templatesWithAttachments[this.template],
      })

      if (type !== 'LOCATION') {
        const form_data = new FormData()
        form_data.append('file', this.file)
        form_data.append('type', this.type)
        form_data.append('key', this.template)
        const res = await this.saveTemplateAssets(form_data, type)

        console.log({
          res,
        })

        this.templatesWithAttachments[this.template]['assets']['id'] = res.data.id
        this.templatesWithAttachments[this.template]['assets']['path'] = res.path

        let videoPreview = this.$el.querySelector('#existedVideoPreview')
        if (videoPreview) videoPreview.load()
      } else {
        let reqData = {
          type,
          key: this.template
        }
        const latitudeRegex = /^[-]?([0-8]?\d(\.\d{1,20})?|90(\.0{1,20})?)$/;
        const longitudeRegex =/^[-]?((1[0-7]\d(\.\d{1,20})?)|([0-9]?\d(\.\d{1,20})?)|180(\.0{1,20})?)$/;

        const isValidLat = latitudeRegex.test(this.location.latitude)

        const isValidLong = longitudeRegex.test(this.location.longitude)
        const isValidName = this.location.name !== ''
        const isValidAddress = this.location.address !== ''

        if (isValidLat && isValidLong && isValidName && isValidAddress) {
          console.log('--- valid ----')
          reqData.location = this.location
          const res = await this.saveTemplateAssets(reqData, type)
          console.log('rrr', { res })
          this.templatesWithAttachments[this.template]['assets']['address'] = res.address
          this.templatesWithAttachments[this.template]['assets']['name'] = res.name
          this.templatesWithAttachments[this.template]['assets']['longitude'] = res.longitude
          this.templatesWithAttachments[this.template]['assets']['latitude'] = res.latitude
        } else {
          console.log('--- not - valid ----')
          this.setAlertMessage({
            display: true,
            text: 'Please insert a valid data',
            class: 'error',
          })
        }
      }
    },
    onClear() {
      console.log('onClear')
      // this.templatesWithAttachments[this.template]['assets']['path'] = null
      this.previewUrl = null
      this.file = null
      this.image = null
      this.video = null
      this.document = null
      this.audio = null
    },
    createImage(file) {
      if (file) {
        const reader = new FileReader()

        reader.onload = (e) => {
          // this.imageUrl = e.target.result
          // this.setTemplateImage(e.target.result)

          // this.templatesWithAttachments[this.template]['assets']['path'] = e.target.result;
          this.previewUrl = e.target.result
        }
        reader.readAsDataURL(file.target.files[0])
      }
    },
    createVideo(file) {
      if (file) {
        const reader = new FileReader()
        reader.onload = (e) => {
          // this.templatesWithAttachments[this.template]['assets']['path'] = e.target.result;
          this.previewUrl = e.target.result

          let videoPreview = this.$el.querySelector('#videoPreview')
          videoPreview.load()
        }
        reader.readAsDataURL(file.target.files[0])
      }
    },
    async onFileChange(event, type) {
      if (!event) {
        return
      }
      this.file = event.target.files ? event.target.files[0] : null

      console.log(this.file, { type })
      if (type === 'image') {
        if (this.file.size < 5000000) {
          this.createImage(event)
        } else {
          this.file = null
        }
      } else if (type === 'video') {
        if (this.file.size < 16000000) {
          this.createVideo(event)
        } else {
          this.file = null
        }
      } else if (type === 'document') {
        if (this.file.size < 100000000) {
          // this.createVideo(event)
        } else {
          this.file = null
        }
      }
    },
    templateChanged() {
      console.log('templateChanged')
      this.previewUrl = null
      this.image = null
      this.video = null
      this.document = null
      this.audio = null
    },
  },
  async updated() { },
  // async created() {
  //   let res = await axios.get(`/setting/wellcome_message`);

  //   console.log({
  //     res
  //   })

  //   if (res.data) {
  //     console.log({
  //       resData: res.data.data
  //     })
  //     this.message = res.data['data']['value']
  //   } else {
  //     this.message = ""
  //   }
  // },

  async mounted() {
    this.getTemplates()
    // axios.get("", {
    //   headers: {
    //     Authorization: "Bearer ",
    //   },
    // });
    // let res = await axios.post(`/messages/get-image`, {img_url:'https://lookaside.fbsbx.com/whatsapp_business/attachments/?mid=3382061442055836&ext=1688910872&hash=ATsi0JjbwA8tFBqECGqZ1PSzsbo_eUaieEJWzUzbD9tTbQ'});
    // console.log({res})
  },
}
</script>
