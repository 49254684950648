<template>
  <div class="app-drawer">
    <v-navigation-drawer location="right" temporary v-model="drawer">
      <h1>Notifications</h1>
      <v-divider class="border-opacity-50"></v-divider>
      <div class="logger">
        <p class="logger-item" v-for="(notification, idx) in getNotifications" :key="idx">{{ notification }}</p>
      </div>
    </v-navigation-drawer>
  </div>
</template>
<style scoped>
.logger{
    overflow-y: auto;
    height: 84vh;
  }
  .logger .logger-item{
    padding: 8px;
    background-color: rgb(152, 152, 233);
    color: #FFF;
    margin-bottom: 5px;
  }
</style>
<script>
import { mapState } from 'pinia'
import { useGlobalStore } from '../../stores/global'
export default {
  inject: ["Emitter"],
  data: () => ({
    drawer: false,
  }),
  computed: {
    ...mapState( useGlobalStore , [ 'getNotifications']),
  },
  mounted(){
    this.Emitter.on('openDrawer', () => {
      this.drawer = true;
    })
  }
};
</script>
