<template>
  <div class="conversations">
    <div>
      <v-row class="m-0">
        <UsersDrawer />

        <v-col class="side-menu text-menu-container d-flex d-sm-none" height="84vh" cols="1">
          <template class="d-flex justify-end toggle-container">
            <v-icon title="Open Menu" icon="mdi-menu" @click="openUsersDrawer"></v-icon>
          </template>
          <!-- <p class="text-menu">Conversations Menu</p>  -->
         </v-col> 

        <v-row class="d-none d-sm-flex m-0" style="margin: 0px">

          <v-col class="side-menu text-menu-container" height="84vh" cols="1" v-if="!showMenuFlag">
            <template class="d-flex justify-end toggle-container">
              <v-icon title="Open Menu" icon="mdi-arrow-right-circle" @click="showSideMenuHandler"></v-icon>
            </template>
            <p class="text-menu">Conversations Menu</p>
          </v-col>

          <v-col class="side-menu" v-if="showMenuFlag" xs="1" sm="3" md="3" lg="3">
            <template class="d-flex justify-end toggle-container">
              <v-icon title="Close Menu" icon="mdi-arrow-left-circle" @click="showSideMenuHandler"></v-icon>
            </template>
            <v-card class="conversations-container" max-width="300" height="84vh">

              <v-tabs v-model="tab" color="deep-purple-accent-4" align-tabs="center">
                <v-tab :value="platforms[0]">Whats app</v-tab>
                <v-tab :value="platforms[1]">Inestagram</v-tab>
              </v-tabs>
              <!-- search bar -->
              <v-card
              class="mx-auto"
              color="surface-light"
              max-width="400"
            >
              <v-card-text>
                <v-text-field
                  :loading="loading"
                  append-inner-icon="mdi-magnify"
                  density="compact"
                  label="Search"
                  variant="solo"
                  hide-details
                  single-line
                  @click:append-inner="onClick"
                  @keypress.enter.prevent="onClick"
                  @update:modelValue="searchValueChanged"
                ></v-text-field>
              </v-card-text>
            </v-card>
            <!-- search bar -->
              <v-list lines="two">
                <v-list-item v-for="item in filterUsers(tab)" :key="item.id" :title="item.name"
                  :subtitle="item?.messages?.length > 0 ? item?.messages[0]['content'] : 'No messages'"
                  @click="viewConversationHandler(item.id)" :class="[
                    { 'active-conversation': item.id == activeConversation },
                    'conversation-item',
                  ]">
                  <template v-slot:prepend>
                    <v-avatar color="grey-lighten-1">
                      <v-icon color="white">mdi-account</v-icon>
                    </v-avatar>
                  </template>

                  <template v-if="item" v-slot:append>
                    <span v-if="tab ? tab !== 'instagram' : false">
                      <v-icon class="new-icon"
                        v-if="item?.messages?.length > 0 && !item?.messages[0]['seen'] && (admin ? item.messages[0]['to'] === admin['id'] : false)"
                        icon="mdi-new-box"></v-icon>

                    </span>
                  </template>
                </v-list-item>
                <v-list-item v-if="filterUsers(tab).length == 0">
                  <p class="text-center">No Users</p>
                </v-list-item>
              </v-list>

            </v-card>
          </v-col>

          <v-col :xs="!showMenuFlag ? 11 : 9" :sm="!showMenuFlag ? 11 : 9" :md="!showMenuFlag ? 11 : 9"
            :lg="!showMenuFlag ? 11 : 9">
            <template v-if="isSubRoute">
              <router-view />
            </template>
            <div style="display: flex; justify-content: center; align-items: center; height: 100%" v-else>
              <v-container>
                <p class="text-center">Select user to Start Conversation</p>
              </v-container>
            </div>
          </v-col>
        </v-row>

        <v-col class="d-flex d-sm-none" cols="11">
          <template v-if="isSubRoute">
            <router-view />
          </template>
          <div style="display: flex; justify-content: center; align-items: center; height: 100%" v-else>
            <v-container>
              <p class="text-center">Select user to Start Conversation</p>
            </v-container>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<style scoped>
.text-menu-container {
  height: 80vh;
  border-right: 1px solid #efe9e9;
}

.text-menu {
  /* margin: auto 0; */
  /* width: 0; */
  white-space: nowrap;
  height: 10px;
  /* position: absolute; */
  text-align: left;
  transform-origin: bottom;
  margin-top: 300px;
  transform: rotate(270deg);
}

.side-menu {
  position: relative;
}

.toggle-container {
  position: absolute;
  top: 30px;
  right: -10px;
  z-index: 2;
}

.conversations-container {
  overflow-y: auto !important;
  border-right: 1px solid !important;
}

.conversation-item {
  border-bottom: 1px solid #efebeb !important;
}

.active-conversation,
.conversation-item:hover {
  background-color: #e7e7e7 !important;
}
</style>
<script>
import UsersDrawer from "../components/global/UsersDrawer";

import { mapState, mapActions } from 'pinia'
import { useUserStore } from '../stores/user'
import { useGlobalStore } from '../stores/global'

export default {
  inject: ['Emitter'],
  name: 'ConversationsView',
  components: {
    UsersDrawer,
  },
  data: () => ({
    tab: 'whatsapp',
    platforms: ['whatsapp', 'instagram'],
    activeConversation: null,
    showMenuFlag: true,
    isSubRoute: false,
    items: [],
    searchValue: '',
    is_search: false
  }),
  computed: {
    ...mapState(useUserStore, [
      'userList',
      'user',
      'admin',
      'filterUsers',
      'instagramUsers',
      'whatsappUsers',
      'receiveMessage',
      'updateMessage',
      'addMessageToUser',
      'addUser'
    ]),
  },
  async updated() {
    this.isSubRoute = this.$route.params.id !== undefined
    if (!this.isSubRoute) {
      this.activeConversation = null
    }
    this.getUsers()

    console.log('Conversations | updated', {
      isSubRoute: this.isSubRoute,
    })
  },
  async beforeMount() {
    console.log('Conversations | beforeMount')
  },
  async activated() {
    console.log('Conversations | activated')
  },
  async mounted() {
    document.title = 'Conversations'
    await this.getAdmin()
    this.getUsers()
    const baseUrl = process.env.VUE_APP_WS_BASE
    //const baseUrl = process.env.VUE_APP_BACK_END_BASE_URL

    // if(this.userList){
    //   console.log("Conversations | mounted", 'users already loaded')
    // }else{
    //   this.getUsers();
    // }

    // eslint-disable-next-line no-undef
    const socket = io.connect(`${baseUrl}`, {
            transports:['websocket']
    })
    socket.on('status', async (data) => {
      console.log({ data })
      if (data.status === 'failed' || data.status === 'fail') {
        // this.$store.commit("SET_NOTIFICATION", {
        //   display: true,
        //   text: data.statusMsg,
        //   class: "error",
        // });
        this.setAlertMessage( {
          display: true,
          text: data.statusMsg,
          class: "error",
        })
      }

      if (this.user) {
        if (data.msg.to === this.user['id']) {
          this.user['messages'].forEach((msg) => {
            if (msg.client_id === data.msg.client_id) {
              msg.status = data.status
              msg.wa_msg_id = data.msg.wa_msg_id
              msg.statusTitle = data.statusMsg
            }
          })
        }
      }
    })

    socket.on('chat', async (data) => {
      console.log("chat", { data })
      const receivedData = data.fromUser[0];
      const palatform = receivedData.platform;

      if (data.fromUser[0]['isNewUser']) {
        this.addUser(receivedData, palatform);
      } else {
        if (this.user) {
          if (this.user['id'] === receivedData.id) {
            this.updateMessage(receivedData.messages[0]._id, { seen: true });
            this.addMessageToUser(receivedData);
          } else {
            // this.receiveMessage(receivedData.messages[0], palatform);
          }
        } else {
          // this.receiveMessage(receivedData.messages[0], palatform);
        }
        this.receiveMessage(receivedData, palatform);

      }
    })
    console.log('Conversations | mounted', {
      id: this.$route.params.id,
      userList: this.whatsappUsers,
    })

    this.isSubRoute = this.$route.params.id !== undefined

    if (this.isSubRoute) {
      this.activeConversation = this.$route.params.id
    }
    this.items = this.tab === 'whatsapp' ? this.whatsappUsers : this.instagramUsers
  },
  methods: {
    ...mapActions(useUserStore, ['getUsers', 'getAdmin', 'setIsUserUpdated', 'search']),
    ...mapActions(useGlobalStore, ['setAlertMessage']),
    viewConversationHandler: function (id) {
      console.log({
        id,
      })
      this.activeConversation = id
      this.setIsUserUpdated()
      this.$router.push({ name: 'conversation', params: { id }, query: { platform: this.tab } })
    },
    showSideMenuHandler: function () {
      console.log('showSideMenuHandler', this.showMenuFlag)
      this.showMenuFlag = !this.showMenuFlag
    },
    openUsersDrawer() {
      console.log('openUsersDrawer')
      this.Emitter.emit('openUsersDrawer')
    },
    onClick: async function () {
      if(this.searchValue !== ''){
        this.is_search = true;
        await this.search(this.searchValue);
      }else{
        if( this.is_search){
          await this.search(this.searchValue);
        }
        this.is_search = false;
      }
    },
    searchValueChanged(value){
      this.searchValue = value;
    }
  },
}
</script>
